import type { PageComponent } from '@/core/features/a-dynamic-page/dynamic-page-pacts/dynamic-page-type';

import dynamic from 'next/dynamic';

import { getComponentId } from '@/core/features/a-component/services/component-id-service';
import { useDeviceoutput } from '@/core/features/cookies/use-device-output';
import ErrorBoundaryWrapper from '@/core/features/error-boundary/error-boundary-wrapper';
import { logger } from '@/core/features/logger/logger';
import useMapOverlayHistoryState from '@/features/map/map-container/use-map-overlay-history';

const MapOverlayDesktop = dynamic(
    () => import(/* webpackChunkName: "MapOverlayDesktop" */ '@/features/map/map-container/map-overlay-desktop'),
);

const MapOverlay = dynamic(
    () => import(/* webpackChunkName: "MapOverlay" */ '@/features/map/map-container/map-overlay'),
);

export default function MapContainer({ components }: { components: PageComponent[] }) {
    const { isDesktopView } = useDeviceoutput();
    const { isOverlayOpen } = useMapOverlayHistoryState();

    if (!isOverlayOpen) {
        return null;
    }

    const mapDataComponent = components.find((component) => component['@type'] === 'MapDataV4');

    if (!mapDataComponent) {
        logger.warn('No MapDataV4 to open Map Overlay', { url: location.href });
        return null;
    }

    return (
        <ErrorBoundaryWrapper fallbackRender={() => null}>
            {isDesktopView ? (
                <MapOverlayDesktop componentId={getComponentId(mapDataComponent['@id'])} />
            ) : (
                <MapOverlay componentId={getComponentId(mapDataComponent['@id'])} />
            )}
        </ErrorBoundaryWrapper>
    );
}
