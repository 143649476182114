import React from 'react';

import { IS_SERVER } from '@/constants/env';
import { getBrowserCookie } from '@/core/features/cookies/cookies-service';
import logger from '@/core/features/logger/logger';

export default function useDeviceoutputReloadFallback() {
    const isInitializedRef = React.useRef(false);
    const isReloadingRef = React.useRef(false);

    React.useEffect(() => {
        const timeout = setTimeout(() => {
            isInitializedRef.current = true;
        }, 2000);

        return () => {
            clearTimeout(timeout);
        };
    }, []);

    if (IS_SERVER || !isInitializedRef.current || isReloadingRef.current) {
        return;
    }

    const deviceoutputCookie = getBrowserCookie('deviceoutput');

    if (deviceoutputCookie) {
        return;
    }

    isReloadingRef.current = true;

    logger.warn('Reloading page because devciceoutput cookies has expired');

    window.location.reload();
}
