import type { PageComponent } from '@/core/features/a-dynamic-page/dynamic-page-pacts/dynamic-page-type';

import React from 'react';

import useComponentQueries from '@/core/features/a-component/hooks/use-component-queries';
import { isNestedComponent } from '@/core/features/a-component/services/component-service';

/**
 * Re-fetches all dynamic page components marked with the loading state
 * partial via the components endpoint.
 *
 * @returns A list of all partial components, now containing their complete data set.
 */
export default function useDynamicPagePartialComponentQuery(pageComponents: PageComponent[]): PageComponent[] {
    const [partialComponents, setPartialComponents] = React.useState<PageComponent[]>([]);
    const cachedUpdatedIdRef = React.useRef<null | string>(null);

    const partialComponentsIris = React.useMemo(() => {
        return pageComponents.flatMap((component) => {
            if (isNestedComponent(component)) {
                return (component.attributes?.components ?? []).flatMap((subComponent) => {
                    return subComponent.loading === 'partial' ? subComponent['@id'] : [];
                });
            }

            return component.loading === 'partial' ? component['@id'] : [];
        });
    }, [pageComponents]);

    const updateId = JSON.stringify(partialComponentsIris);

    const partialComponentQueries = useComponentQueries(partialComponentsIris);

    const hasPendingQueries = partialComponentQueries.some((query) => query.isLoading || query.isFetching);

    const shouldUpdateComponents =
        !hasPendingQueries && pageComponents.length > 0 && cachedUpdatedIdRef.current !== updateId;

    React.useEffect(() => {
        if (!shouldUpdateComponents) {
            return;
        }
        setPartialComponents(partialComponentQueries.flatMap((query) => (!query.data ? [] : query.data)));
        cachedUpdatedIdRef.current = updateId;
    }, [partialComponentQueries, pageComponents, shouldUpdateComponents, updateId]);

    return partialComponents;
}
