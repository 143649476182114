import type {
    DynamicPage_jsonld_page_read,
    DynamicPageMeta,
    PageComponent,
} from '@/core/features/a-dynamic-page/dynamic-page-pacts/dynamic-page-type';
import type { SsrProps } from '@/core/features/app/ssr-props';

import React from 'react';

import { ErrorPage } from '@/core/features';
import useDynamicPagePartialComponentQuery from '@/core/features/a-dynamic-page/hooks/use-dynamic-page-partial-component-query';
import {
    useDynamicPageQueryData,
    useHydrateDynamicPageQueryData,
} from '@/core/features/a-dynamic-page/hooks/use-dynamic-page-query';
import useDynamicPageQueryCacheHandler from '@/core/features/a-dynamic-page/hooks/use-dynamic-page-query-cache-handler';
import {
    isDynamicPageErrorResponse,
    mergeDynamicPageComponentsWithPartialComponents,
} from '@/core/features/a-dynamic-page/services/dynamic-page-service';
import useDeviceoutputReloadFallback from '@/core/features/cookies/use-deviceoutput-fallback';
import { logger } from '@/core/features/logger/logger';
import { useHydrateOverlayHistoryState } from '@/core/features/modals/overlay-history/overlay-history-state';
import Main from '@/core/features/page/main';
import { isAxiosError } from '@/core/features/request/axios';
import useRouterInit from '@/core/features/router/router-init';
import usePageScrollRestoration from '@/core/features/scroll/page-scroll-restoration/use-page-scroll-restoration';
import TrackingContainer from '@/core/features/tracking/tracking-container';
import DynamicPage from '@/features/a-dynamic-page/components/dynamic-page';
import PageDataComponentsSwitch from '@/features/a-dynamic-page/components/page-data-components-switch';

export default function DynamicPageContainer(ssrProps: SsrProps) {
    useDeviceoutputReloadFallback();
    useHydrateDynamicPageQueryData();
    const { data: newData, error, isLoading, isPending } = useDynamicPageQueryData();

    const updatedPartialComponents = useDynamicPagePartialComponentQuery(newData?.response?.components ?? []);

    const [data, setData] = React.useState<DynamicPage_jsonld_page_read | undefined>();
    const [components, setComponents] = React.useState<PageComponent[]>(newData?.response?.components ?? []);
    const [meta, setMeta] = React.useState<DynamicPageMeta | null>(newData?.response?.meta ?? null);

    const [errorStatusCode, setErrorStatusCode] = React.useState<null | number>(
        isDynamicPageErrorResponse(newData?.response) ? newData.response.code ?? null : null,
    );

    useRouterInit({ network: { isLoading: isPending } });
    usePageScrollRestoration();
    useHydrateOverlayHistoryState();

    // currently, this has to be here so that the invalidation is only done by one observer
    useDynamicPageQueryCacheHandler({
        cacheControl: data?.meta.cacheControl ?? null,
        identifier: data?.['@id'] ?? null,
        isCachedResponse: newData?.cached ?? false,
    });

    React.useEffect(() => {
        if (isLoading) {
            return;
        }

        setData(newData?.response);

        if (isAxiosError(error)) {
            setErrorStatusCode(error.response?.status ?? null);
            return;
        }

        if (isDynamicPageErrorResponse(data)) {
            setErrorStatusCode(data?.code);
            return;
        }

        if (error) {
            logger.error(`Error in dynamic-page-container: ${error} - ${JSON.stringify(error)}`);
            return;
        }

        if (!data) {
            return;
        }

        if (typeof data !== 'object') {
            return;
        }

        const { meta } = data;
        const updatedComponents = mergeDynamicPageComponentsWithPartialComponents(
            data.components,
            updatedPartialComponents,
        );
        setComponents(updatedComponents);
        setErrorStatusCode(null);
        setMeta(meta);
    }, [data, error, isLoading, newData?.response, updatedPartialComponents]);

    if (errorStatusCode && errorStatusCode !== 301 && errorStatusCode !== 302) {
        return (
            <Main>
                <ErrorPage
                    detail={'API'}
                    isPageLoading={false}
                    statusCode={errorStatusCode}
                />
            </Main>
        );
    }

    return (
        <>
            <PageDataComponentsSwitch components={components} />
            <DynamicPage
                components={components}
                isPageLoading={isLoading}
                meta={meta}
                ssrProps={ssrProps}
            />
            <TrackingContainer
                metaData={meta}
                url={ssrProps.ssrUrl}
            />
        </>
    );
}
